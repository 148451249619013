<template>
  <div class="page-main">报价单列表</div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
